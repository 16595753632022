<template>
  <v-dialog v-model="modalEditar" persistent max-width="340px">
    <v-card>
      <v-card-title class="secondary white--text"
        >Seleccione la fecha</v-card-title
      >
      <v-card-text class="pt-12">
        <v-date-picker
          class="primary"
          v-model="item.fecha_caducidad"
          locale="es"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn @click.stop="$emit('cerrar')">Cerrar</v-btn>
        <v-btn
          @click.stop="showModarConfirm = true"
          :disabled="!item.fecha_caducidad"
          class="secondary"
        >
          Ampliar caducidad
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationDialogComponent
      :show="showModarConfirm"
      btnConfirmar="Actualizar"
      :title="`¿Desea actualizar el empleado?`"
      @close="showModarConfirm = false"
      @confirm="actualizarEmpleado"
    />
  </v-dialog>
</template>
<script>
import moment from "moment";
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";

export default {
  name: "modalEditarCaducidadEmpleado",
  components: { ConfirmationDialogComponent },
  data: () => ({
    item: {},
    showModarConfirm: false,
    config_calendar: {
      visibility: "click",
    },
  }),
  methods: {
    async actualizarEmpleado() {
      await this.services.EmpleadosProveedor.editarEmpleado(this.id, this.item);
      this.showModarConfirm = false;
      this.$emit("cerrar");
    },
    getFechaInicioParametrizable(fecha) {
      return fecha
        ? moment(fecha).format("DD/MM/YYYY hh:mm A")
        : "Pendiente de configurar";
    },
  },
  props: {
    modalEditar: {
      type: Boolean,
    },
    id: {
      type: Number,
    },
    fecha: {
      type: Date,
    },
  },
};
</script>
