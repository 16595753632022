<template>
  <div>
    <v-dialog v-model="modalAgregar" persistent>
      <v-card elevation="12">
        <v-card-title class="secondary white--text">
          Creación de nuevo perfil
        </v-card-title>
        <v-card-text class="pt-12">
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <v-form action="" @submit.prevent="submit" style="width: 100%">
                <v-autocomplete
                  outlined
                  color="secondary"
                  label="Documento de identificación  *"
                  :items="tipo_identificaciones"
                  item-text="nombre"
                  item-value="id"
                  :disabled="tipo_identificaciones_disable"
                  :error-messages="identificacionTributariaError"
                  v-model="id_tipo_identificacion_input"
                  @change="seleccionar_tipo_identificacion($event)"
                />
                <v-text-field
                  :disabled="formulario_uno.id_tipo_identificacion == null"
                  :readonly="dui_verificado"
                  outlined
                  color="secondary"
                  label="Número de documento *"
                  :error-messages="numeroDocumentoError"
                  v-model="numero_documento_input"
                  :placeholder="mascara_documento"
                  :maxlength="lng_doc"
                  @blur="$v.formulario_uno.numero_documento.$touch()"
                />
                <template v-if="id_tipo_identificacion_input === 1">
                  <VueSimpleRecaptcha
                    v-if="isDui(numero_documento_input) && mostrar"
                    :sitekey="key"
                    @callback="callback"
                  />
                  <v-btn
                    v-show="
                      isDui(numero_documento_input) && formulario_uno.captcha
                    "
                    :disabled="dui_verificado"
                    :loading="verificando_dui"
                    block
                    x-large
                    color="success"
                    type="submit"
                  >
                    {{ dui_verificado ? "Verificado" : "Verificar" }}
                  </v-btn>
                </template>
              </v-form>
            </v-col>
            <v-col cols="12" md="6" lg="6" v-if="dui_verificado">
              <v-text-field
                color="secondary"
                label="Nombre del empleado"
                :value="nombre_proveedor_input"
                v-model="nombre_proveedor_input"
                readonly
                dense
                class="no-border"
                outlined
              />
              <br />
              <v-text-field
                color="secondary"
                label="Identificación tributaria"
                :value="formulario_uno.tipo_identificacion.nombre"
                readonly
                dense
                class="no-border"
                outlined
              />
              <br />
              <v-text-field
                v-if="id_tipo_contribuyente_input === 1"
                color="secondary"
                :label="formulario_uno.tipo_identificacion.tipo_documento"
                :value="numero_documento_input"
                readonly
                hint="El número de DUI es válido"
                persistent-hint
                dense
                class="no-border green-hint"
                outlined
              />
              <br v-if="id_tipo_contribuyente_input === 1" />
              <v-text-field
                outlined
                color="secondary"
                label="Tipo de contribuyente"
                :value="formulario_uno.tipo_contribuyente"
                readonly
                dense
                class="no-border"
              />
            </v-col>
          </v-row>
          <template
            v-if="
              (id_tipo_identificacion_input !== 1 &&
                id_tipo_identificacion_input !== null) ||
              dui_verificado
            "
          >
            <v-row v-if="id_tipo_identificacion_input !== 1">
              <v-col md="3" cols="6">
                <v-text-field
                  label="Primer nombre"
                  outlined
                  clearable
                  v-model="form.persona.primer_nombre"
                  @blur="$v.form.persona.primer_nombre.$touch()"
                  :error-messages="primerNombreErrors"
                />
              </v-col>
              <v-col md="3" cols="6">
                <v-text-field
                  label="Segundo nombre"
                  outlined
                  clearable
                  v-model="form.persona.segundo_nombre"
                />
              </v-col>
              <v-col md="3" cols="6">
                <v-text-field
                  label="Primer apellido"
                  outlined
                  clearable
                  v-model="form.persona.primer_apellido"
                  @blur="$v.form.persona.primer_apellido.$touch()"
                  :error-messages="primerApellidoErrors"
                />
              </v-col>
              <v-col md="3" cols="6">
                <v-text-field
                  label="Segundo apellido"
                  outlined
                  clearable
                  v-model="form.persona.segundo_apellido"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" cols="6">
                <v-text-field
                  label="Email"
                  outlined
                  clearable
                  type="text"
                  v-model="form.email"
                  @blur="$v.form.email.$touch()"
                  :error-messages="emailErrors"
                />
              </v-col>
              <v-col md="4" cols="6">
                <v-text-field
                  label="Cargo"
                  outlined
                  clearable
                  v-model="form.cargo"
                  @blur="$v.form.cargo.$touch()"
                  :error-messages="cargoErrors"
                />
              </v-col>
              <v-col md="4" cols="6">
                <vc-date-picker
                  v-model="form.vencimiento"
                  mode="dateTime"
                  locale="es"
                  :popover="config_calendar"
                  :min-date="fecha_actual"
                  :minute-increment="5"
                >
                  <template v-slot="{ inputEvents }">
                    <v-text-field
                      class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                      :value="getFechaInicioParametrizable(form.vencimiento)"
                      readonly
                      v-on="inputEvents"
                      outlined
                      :error-messages="fechaVencimientoErrors"
                      @input="$v.form.vencimiento.$touch()"
                      @blur="$v.form.vencimiento.$touch()"
                    />
                  </template>
                </vc-date-picker>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn @click.stop="cerrar">Cerrar</v-btn>
          <v-btn @click.stop="validarCreacion()" class="secondary" :disabled="!disableBtn">
            Registrar perfil
          </v-btn>
        </v-card-actions>
      </v-card>
      <confirmation-dialog-component
        :show="modal_confirmacion"
        title="¿Todos los datos ingresados son correctos?"
        btnConfirmar="Enviar"
        @close="modal_confirmacion = false"
        @confirm="enviarCreacion"
      />
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { required, helpers } from "vuelidate/lib/validators";
import VueSimpleRecaptcha from "vue-simple-recaptcha";
import { mapActions, mapState, mapMutations } from "vuex";
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";

const validarFormatoRegex = (formato) => {
  const validaciones = formato.split("|");
  const expresiones = validaciones.map((validacion) => {
    return new RegExp(`^${validacion.replace(/#/gi, "\\d")}$`);
  });

  return (value) => expresiones.some((expresion) => expresion.test(value));
};
const email = helpers.regex(
  "email",
  /^[a-zA-Z\d]+[a-zA-Z\d._-]*@([a-zA-Z\d]+(-|.)?){0,3}[a-zA-Z\d]+(\.[a-zA-Z\d]+)?(\.[a-zA-Z\d]{2,})$/
);
export default {
  name: "IndexEmpleadosProveedor",
  data: () => ({
    modal_confirmacion: false,
    mostrar: true,
    key: process.env.VUE_APP_CAPTCHA,
    form: {
      email: null,
      persona: {
        primer_nombre: "",
        segundo_nombre: "",
        primer_apellido: "",
        segundo_apellido: "",
      },
      cargo: null,
      vencimiento: null,
    },
    defaultForm: {
      email: null,
      persona: {
        primer_nombre: "",
        segundo_nombre: "",
        primer_apellido: "",
        segundo_apellido: "",
      },
      cargo: null,
      vencimiento: null,
    },
    config_calendar: {
      visibility: "click",
    },
  }),
  components: {
    VueSimpleRecaptcha,
    ConfirmationDialogComponent,
  },
  validations() {
    const formulario_uno = {
      id_tipo_contribuyente: { required },
      id_tipo_identificacion: { required },
      numero_documento: {
        required,
        ...(this.mascara_documento && {
          regex: validarFormatoRegex(this.mascara_documento),
        }),
      },
      nombre_proveedor: { required },
    };

    const form = {
      email: {
        required,
        email,
      },
      cargo: {
        required,
      },
      vencimiento: {
        required,
      },
    };

    if (!this.id_persona) {
      form.persona = {
        primer_nombre: {
          required,
        },
        segundo_nombre: {
          required,
        },
        primer_apellido: {
          required,
        },
        segundo_apellido: {
          required,
        },
      };
      form.numero_documento = {
        required,
      };
    }

    return {
      formulario_uno,
      form,
    };
  },
  props: { modalAgregar: { type: Boolean } },
  computed: {
    ...mapState("registroProveedor", [
      "tipo_identificaciones_disable",
      "tipo_identificaciones",
      "mascara_documento",
      "lng_doc",
      "formulario_uno",
      "dui_verificado",
      "verificando_dui",
      "id_persona",
    ]),
    identificacionTributariaError() {
      const errors = [];
      if (!this.$v.formulario_uno.id_tipo_identificacion.$dirty) return errors;
      !this.$v.formulario_uno.id_tipo_identificacion.required &&
        errors.push("La identificación tributaria es requerida");
      return errors;
    },
    numeroDocumentoError() {
      const errors = [];
      if (!this.$v.formulario_uno.numero_documento.$dirty) return errors;

      !this.$v.formulario_uno.numero_documento.required &&
        errors.push("El número de documento es requerido");

      if (
        this.mascara_documento &&
        !this.$v.formulario_uno.numero_documento.regex
      ) {
        errors.push(
          `El número de documento debe seguir el formato: ${this.mascara_documento
            .split("|")
            .join(" o ")}`
        );
      }

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required &&
        errors.push("El correo es necesario para continuar");
      !this.$v.form.email.email &&
        errors.push("Ingresa un correo con formato válido");
      return errors;
    },
    primerNombreErrors() {
      const errors = [];
      if (!this.$v.form.persona.primer_nombre.$dirty) return errors;
      !this.$v.form.persona.primer_nombre.required &&
        errors.push("El primer nombre es necesario para continuar");
      return errors;
    },
    primerApellidoErrors() {
      const errors = [];
      if (!this.$v.form.persona.primer_apellido.$dirty) return errors;
      !this.$v.form.persona.primer_apellido.required &&
        errors.push("El primer apellido es necesario para continuar");
      return errors;
    },
    cargoErrors() {
      const errors = [];
      if (!this.$v.form.cargo.$dirty) return errors;
      !this.$v.form.cargo.required &&
        errors.push("El cargo es necesario para continuar");
      return errors;
    },
    fechaVencimientoErrors() {
      const errors = [];
      if (!this.$v.form.vencimiento.$dirty) return errors;
      !this.$v.form.vencimiento.required && errors.push("Campo requerido");
      return errors;
    },
    fecha_actual() {
      return moment().locale("es").format("YYYY-MM-DD HH:mm");
    },
    id_tipo_contribuyente_input: {
      get() {
        return this.formulario_uno.id_tipo_contribuyente;
      },
      set(value) {
        this.formulario_uno.id_tipo_contribuyente = value;
      },
    },
    id_tipo_identificacion_input: {
      get() {
        return this.formulario_uno.id_tipo_identificacion;
      },
      set(value) {
        this.formulario_uno.id_tipo_identificacion = value;
      },
    },
    numero_documento_input: {
      get() {
        return this.formulario_uno.numero_documento;
      },
      set(value) {
        this.formulario_uno.numero_documento = value;
      },
    },
    nombre_proveedor_input: {
      get() {
        return this.formulario_uno.nombre_proveedor;
      },
      set(value) {
        this.formulario_uno.nombre_proveedor = value;
      },
    },
    disableBtn() {
      return (
        (this.id_persona ||
          (this.form.persona.primer_nombre &&
            this.form.persona.primer_apellido &&
            this.numero_documento_input)) &&
        this.form.email &&
        this.form.cargo &&
        this.form.vencimiento
      );
    },
  },
  methods: {
    ...mapMutations("registroProveedor", ["setDuiVerificado", "setIdPersona"]),
    ...mapActions("registroProveedor", [
      "obtener_tipos_contribuyentes",
      "obtener_tipos_identificaciones",
      "seleccionar_tipo_identificacion",
      "verificar_dui",
    ]),
    async callback(token) {
      this.formulario_uno.captcha = token;
      this.mostrar = false;
    },
    async submit() {
      if (!this.formulario_uno.captcha) {
        return this.temporalAlert({
          show: true,
          message: "Debe verificar el captcha",
          type: "error",
        });
      }

      await this.verificar_dui();
      this.token = null;
    },
    async enviarCreacion() {
      await this.services.EmpleadosProveedor.agregarEmpleado({
        ...this.form,
        numero_documento: this.numero_documento_input,
        id_persona: this.id_persona,
        id_tipo_identificacion: this.id_tipo_identificacion_input,
      });
      this.cerrar();
    },
    validarCreacion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        return this.temporalAlert({
          show: true,
          message: "Debe verificar el captcha",
          type: "error",
        });
      }
      this.modal_confirmacion = true;
    },
    cerrar() {
      this.modal_confirmacion = false;
      this.setIdPersona(null);
      this.setDuiVerificado(false);
      this.formulario_uno.captcha = null;
      this.id_tipo_identificacion_input = null;
      this.numero_documento_input = null;
      this.form = this.defaultForm;
      this.$v.$reset();
      this.$emit("cerrar");
    },
    getFechaInicioParametrizable(fecha) {
      return fecha
        ? moment(fecha).format("DD/MM/YYYY hh:mm A")
        : "Configure la fecha de vencimiento";
    },
  },
  async created() {
    await this.obtener_tipos_contribuyentes();
    await this.obtener_tipos_identificaciones(1);
  },
};
</script>
